export const TestZone = [
  {
    id: 1,
    name: "app-config.json",
    url: "https://raw.githubusercontent.com/ixofoundation/ixo-webclient/dev/configs/testzone/app-config.json",
  },
  {
    id: 2,
    name: "config.json",
    url: "https://raw.githubusercontent.com/ixofoundation/ixo-webclient/dev/configs/testzone/config.json",
  },
  {
    id: 3,
    name: "exchange.json",
    url: "https://raw.githubusercontent.com/ixofoundation/ixo-webclient/dev/configs/testzone/exchange.json",
  },
];

export const MainNet = [
  {
    id: 1,
    name: "app-config.json",
    url: "https://raw.githubusercontent.com/ixofoundation/ixo-webclient/impact/configs/launchpad/app-config.json",
  },
  {
    id: 2,
    name: "config.json",
    url: "https://raw.githubusercontent.com/ixofoundation/ixo-webclient/impact/configs/launchpad/config.json",
  },
];