/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { Button, Center, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {TestZone} from "../Links";

export const TestZones = () => (
  <Center width={"full"}>
    <Stack spacing={5} padding={25} width={"full"} height={"auto"}>
      {TestZone.map((link, index) => (
        <Link to={`/Editor`} state={{ data: `${link.url}` }}>
          <Button
            borderRadius={15}
            borderWidth={2}
            borderColor={"#00D2FF"}
            color={"white"}
            backgroundColor={"#08222F"}
            padding={2}
            key={index}
            width={"full"}
          >
            {link.name}
          </Button>
        </Link>
      ))}
      <Link to={`/NewJsonFile`}>
        <Button
          borderRadius={15}
          borderWidth={2}
          borderColor={"#00d2ff"}
          color={"white"}
          backgroundColor={"#00D2FF"}
          padding={2}
          width={"full"}
        >
          New JSON File
        </Button>
      </Link>
    </Stack>
  </Center>
);
