import * as React from "react";
import { Home } from "./screens/Home";
import { Route, Routes } from "react-router-dom";
import {
  Center,
  Container,
  Heading,
  HStack,
  Image,
  VStack,
} from "@chakra-ui/react";
import Editor from "./screens/Editor";
import NewJsonFile from "./screens/NewJsonFile";
import { TestZones } from "./screens/TestZone";
import { Launchpads } from "./screens/Launchpad";
import "@fontsource/roboto";

export const App = () => (
  <Container width={"full"} height={"auto"}>
    <HStack width={"full"}>
      <Center width={"full"} >
        <VStack paddingRight={4} paddingTop={5}>
          <Image src="./ixo_logo.png" alt="Ixo Logo" />
        </VStack>
        <VStack>
          <Heading
            fontWeight={"light"}
            fontStyle={"normal"}
            fontSize={45}
            lineHeight={"inherit"}
            paddingTop={8}
            color={"white"}
          >
            CONFIG MANAGER
          </Heading>
        </VStack>
      </Center>
    </HStack>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/TestZone" element={<TestZones />} />
      <Route path="/Launchpad" element={<Launchpads />} />
      <Route path="/Editor" element={<Editor />} />
      <Route path="/NewJsonFile" element={<NewJsonFile />} />
    </Routes>
  </Container>
);
