/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { Box, Button, Center, HStack, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useState } from "react";
import ReactJson from "react-json-view";

const YAML = require('json-to-pretty-yaml');

export default function Creator() {
  const [star, setStar] = React.useState({});
  const [setOnAdd] = useState(true);
  const [setOnEdit] = useState(true);
  const [setOnDelete] = useState(true);

  const exportDataJson = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(star)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = String(new Date(Date.now()).toLocaleDateString()) + "data.json";

    link.click();
  };
  
  const exportDataYaml = () => {
    const yamlString = `data:text/yaml;chatset=utf-8,${encodeURIComponent(
      YAML.stringify(star)
    )}`;
    const link = document.createElement("a");
    link.href = yamlString;
    link.download = String(new Date(Date.now()).toLocaleDateString()) + "data.yml";

    link.click();
  };

  return (
    <Center width={"full"}>
      <Stack spacing={5} padding={5} width={"full"} height={"auto"}>
        <Box
          borderRadius={8}
          borderWidth={2}
          borderColor={"#00d2ff"}
          color={"white"}
          backgroundColor={"#08222F"}
          padding={2}
          alignSelf={"center"}
          width={"container.md"}
        >
          <ReactJson
            src={star}
            theme={"codeschool"}
            collapsed={false}
            collapseStringsAfterLength={15}
            onEdit={(e) => {
              console.log(e);
              //@ts-ignore
              setOnEdit!(setStar(e.updated_src));
            }}
            onDelete={(e) => {
              console.log(e);
              //@ts-ignore
              setOnDelete!(setStar(e.updated_src));
            }}
            onAdd={(e) => {
              console.log(e);
              //@ts-ignore
              setOnAdd!(setStar(e.updated_src));
            }}
            displayObjectSize={true}
            enableClipboard={true}
            indentWidth={4}
            displayDataTypes={true}
            iconStyle={"circle"}
          />
        </Box>
        <Link to="/">
          <Button
            borderRadius={8}
            borderWidth={2}
            width={"full"}
            borderColor={"#00d2ff"}
            color={"white"}
            backgroundColor={"#08222F"}
            padding={2}
          >
            back
          </Button>
        </Link>
        <HStack>
          <Button
            borderRadius={8}
            borderWidth={2}
            borderColor={"#00d2ff"}
            color={"white"}
            backgroundColor={"#00D2FF"}
            padding={2}
            width={"full"}
            onClick={exportDataJson}
          >
            export Json
          </Button>
          <Button
            borderRadius={8}
            borderWidth={2}
            borderColor={"#08222F"}
            color={"white"}
            backgroundColor={"#00D2FF"}
            padding={2}
            width={"full"}
            onClick={exportDataYaml}
          >
            Export Yaml
          </Button>
        </HStack>
      </Stack>
    </Center>
  );
}
