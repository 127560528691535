/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { Button, Center, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const Home = () => (
  <Center width={"full"}>
    <Stack spacing={5} padding={25} width={"full"} height={"auto"}>
      <Link to={`/Launchpad`}>
        <Button
          borderRadius={15}
          borderWidth={2}
          borderColor={"#00d2ff"}
          color={"white"}
          backgroundColor={"#08222F"}
          padding={2}
          width={"full"}
        >
          Launchpad
        </Button>
      </Link>
      <Link to={`/TestZone`}>
        <Button
          borderRadius={15}
          borderWidth={2}
          borderColor={"#00d2ff"}
          color={"white"}
          backgroundColor={"#08222F"}
          padding={2}
          width={"full"}
        >
          TestZone
        </Button>
      </Link>
    </Stack>
  </Center>
);
